import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Turkish Get Ups`}</p>
    <p>{`then,`}</p>
    <p>{`Shuttle Runs: 10 Rounds of 70ftx4  (down back twice on turf) with :30
rest between rounds.`}</p>
    <p>{`then,`}</p>
    <p>{`100 Kettlebell Swings for time.`}{`*`}{`(53/35)(RX+ 70/53)`}</p>
    <p>{`*`}{`EMOM: 6 Burpees (RX+=8 Burpees)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday we will be shooting a video during the 10:30 class.
 Please sign up and fill that class before Thursday!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      